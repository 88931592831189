
.App {
  display: flex;
}

:root {
  --primary-font-size: 1.6rem;
  --primary-backgroud-color: #f3f6fc
}

@font-face {
  font-family: "SourceHanSansSC-VF";
  src: url("./assets/SourceHanSansSC-VF.ttf.woff2") format("woff2");
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
  font-family: "SourceHanSansSC-VF", "Helvetica Neue", sans-serif;
}

html {
  /* rem em */
  /*em 相对于父元素的font-size*/
  /*rem 相对于根元素html的font-size, r就是root的意思*/
  /*16 * 62.5% = 10px*/
  /*1rem === 10px*/
  font-size: 62.5%;
}

/*viewport height === vh*/
html body #root .App {
  min-height: 100vh;
}

body::-webkit-scrollbar{
  display: none;
}

.grecaptcha-badge {
  visibility: hidden;
}

